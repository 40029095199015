import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { getIdToken } from 'firebaseAPI';

/**
 * Create dynamic link
 */
export const releaseTerminal = async (
  merchantId: string,
  tid: string,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/api/v2/terminals/${tid}/release`,
      {
        merchantId,
      },
      {
        headers: { token: idToken },
      },
    );
    return response;
  } catch (error) {
    throw error;
  }
};
