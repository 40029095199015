import axios from 'axios';
import { getIdToken } from 'firebaseAPI';

export const restartApp = async (
  tid: string,
  accountId: string,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `https://pos.api.vibrant.app/pos/v1/terminals/${tid}/restart_app`,
      { accountId },
      {
        headers: { token: idToken },
      },
    );
    return response;
  } catch (error) {
    throw error;
  }
};
