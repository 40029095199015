import { Box } from 'components';
import {
  isInternalVibrantSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Firebase } from 'types/firebase';
import { Button, Tag } from 'antd';
const VIBRANT_AID = 'acct_66614e47-7ac7-5945-8738-c799e12444fd';
const VIBRANT_MID = '000000030000030';

type Props = {
  merchant: Firebase.Merchant;
};
const ProxyUserSettings: React.FC<Props> = (props) => {
  const isInternalVibrant = useSelector(isInternalVibrantSelector);
  const authenticatedUser = useSelector(userSelector);
  const isVibrantId = authenticatedUser?.accountId === VIBRANT_AID;
  const firestore = useFirestore();
  const merchantName =
    props.merchant?.merchantTradingName ??
    props?.merchant?.businessLegalName ??
    'No Merchant Name?';
  if (!isInternalVibrant) {
    return null;
  }
  /**
   * _onAlertProxy
   */
  const _onAlertProxy = async () => {
    window.confirm(
      `Are you sure you want to set your Vibrant user as ${merchantName}`,
    )
      ? await _onSetProxyUser()
      : null;
  };
  /**
   * _onAlertResetProxy
   */
  const _onAlertResetProxy = async () => {
    window.confirm('Are you sure you want to reset Vibrant user?')
      ? await _onResetProxyUser()
      : null;
  };
  /**
   * _onSetProxyUser
   */
  const _onSetProxyUser = async () => {
    try {
      if (!props.merchant?.accountId && props.merchant?.merchantId) {
        return window.alert('Missing accountId or merchantId');
      }
      await firestore
        .collection(FirebaseCollectionsType.USERS)
        .doc(authenticatedUser?.id)
        .update({
          accountId: props.merchant?.accountId,
          'meta.merchantId': props.merchant?.merchantId,
        });
      location.reload();
    } catch (error) {
      throw error;
    }
  };
  /**
   *_onResetProxyUser
   */
  const _onResetProxyUser = async () => {
    try {
      await firestore
        .collection(FirebaseCollectionsType.USERS)
        .doc(authenticatedUser?.id)
        .update({
          accountId: VIBRANT_AID,
          'meta.merchantId': VIBRANT_MID,
        });
      location.reload();
    } catch (error) {
      throw error;
    }
  };
  return (
    <Box mt="24px" mb="16px" width="100%">
      <Button
        style={{ width: '100%' }}
        type={isVibrantId ? 'default' : 'primary'}
        onClick={isVibrantId ? _onAlertProxy : _onAlertResetProxy}
      >
        {`${
          isVibrantId
            ? 'Set your Vibrant User as ' + merchantName
            : 'Reset your Vibrant User'
        }`}
      </Button>
    </Box>
  );
};

export { ProxyUserSettings };
