/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';
import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const firestoreRootSelector = (state: VibrantlyState) =>
  state.firestore?.data;
export const rootSelector = (state: VibrantlyState) => state.user;

export const maybeUserSelector = createSelector(
  [rootSelector],
  (root) => root.user,
);

export const userSelector = createSelector(
  [rootSelector],
  (root) => root.user!,
);
export const emailSelector = createSelector(
  [rootSelector],
  (root) => root.email,
);

export const isLink4PaySupporterSelector = createSelector(
  [rootSelector],
  (root) => root.user?.orgId.includes('SoftLink'),
);
export const isGreenlandSupporterSelector = createSelector(
  [rootSelector],
  (root) => root.user?.meta?.countries?.includes('GL'),
);
export const isAdminSelector = createSelector([rootSelector], (root) =>
  root.user?.userRoles.includes('admin'),
);
export const isKYCSelector = createSelector([rootSelector], (root) =>
  root.user?.userRoles.includes('kyc'),
);

export const isSoftPosSupportSelector = createSelector([rootSelector], (root) =>
  root.user?.userRoles.includes('softpos-supporter'),
);
export const isInternalVibrantSelector = createSelector(
  [rootSelector],
  (root) => root.user?.userRoles.includes('is-vibrant'),
);

export const isAuthenticatedSelector = createSelector(
  [rootSelector],
  (x) => x.user != null,
);
