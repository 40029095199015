/**
 * Imports
 */

import * as React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { styled } from 'theme';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { AccountPage, LoginPage } from 'features/user/pages';
import { Trans } from 'react-i18next';
import { HomePage } from 'features/navigation/pages';
import { EmailLeadsPage } from 'features/email-leads/pages';
import { MerchantPage, MerchantsPage } from 'features/merchants/pages';
import { TransactionsPage } from 'features/transactions';
import { SignupsPage } from 'features/signups/pages';
import { CreateMerchantPage } from 'features/create-merchant/pages';
import { CreateFaroeIslandMerchantPage } from 'features/create-faroeisland-merchant/pages';
import { CreateGreenlandMerchantPage } from 'features/create-greenland-merchant/pages';
import { SettingsPage } from 'features/settings';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.Background.White};

  height: 100%;
  min-height: 100%;
`;

/**
 * Types
 */
export type RoutesType =
  | 'home'
  | 'account'
  | 'login'
  | 'signups'
  | 'merchants'
  | 'emailLeads'
  | 'merchant'
  | 'settings'
  | 'create-merchant'
  | 'transactions'
  | 'create-faroeisland-merchant'
  | 'create-greenland-merchant';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: string | React.ReactNode;
  component: React.FC;
  path: string;
  route: string;
  pageId: RoutesType;
  isPrivate: boolean;
};

export interface RootRouterProps {}

/**
 * Routes settings
 */
export const ROUTES: RouteLookup = {
  home: {
    pageName: <Trans i18nKey="Global.pages.home" />,
    component: HomePage,
    pageId: 'home',
    path: '/',
    route: '/',
    isPrivate: true,
  },
  account: {
    pageName: <Trans i18nKey="Global.pages.account" />,
    component: AccountPage,
    pageId: 'account',
    path: '/account',
    route: '/account',
    isPrivate: true,
  },
  login: {
    pageName: <Trans i18nKey="Global.pages.login" />,
    component: LoginPage,
    pageId: 'login',
    path: '/login',
    route: '/login',
    isPrivate: false,
  },
  signups: {
    pageName: <Trans i18nKey="Global.pages.signups" />,
    component: SignupsPage,
    pageId: 'signups',
    path: '/signups',
    route: '/signups',
    isPrivate: true,
  },
  merchants: {
    pageName: <Trans i18nKey="Global.pages.merchants" />,
    component: MerchantsPage,
    pageId: 'merchants',
    path: '/merchants',
    route: '/merchants',
    isPrivate: true,
  },
  merchant: {
    pageName: <Trans i18nKey="Global.pages.merchant" />,
    component: MerchantPage,
    pageId: 'merchant',
    route: '/merchants',
    path: '/merchants/:merchantId',
    isPrivate: true,
  },
  emailLeads: {
    pageName: <Trans i18nKey="Global.pages.email_leads" />,
    component: EmailLeadsPage,
    pageId: 'emailLeads',
    path: '/email-leads',
    route: '/email-leads',
    isPrivate: true,
  },
  ['create-merchant']: {
    pageName: <Trans i18nKey="Global.pages.create_merchant" />,
    component: CreateMerchantPage,
    pageId: 'create-merchant',
    path: '/create-merchant',
    route: '/create-merchant',
    isPrivate: true,
  },
  settings: {
    pageName: <Trans i18nKey="Global.pages.transactions" />,
    component: SettingsPage,
    pageId: 'settings',
    path: '/settings',
    route: '/settings',
    isPrivate: true,
  },
  transactions: {
    pageName: <Trans i18nKey="Global.pages.transactions" />,
    component: TransactionsPage,
    pageId: 'transactions',
    path: '/transactions',
    route: '/transactions',
    isPrivate: true,
  },
  ['create-faroeisland-merchant']: {
    pageName: <Trans i18nKey="Global.pages.create_merchant" />,
    component: CreateFaroeIslandMerchantPage,
    pageId: 'create-faroeisland-merchant',
    path: '/create-faroeisland-merchant',
    route: '/create-faroeisland-merchant',
    isPrivate: true,
  },
  ['create-greenland-merchant']: {
    pageName: <Trans i18nKey="Global.pages.create_merchant" />,
    component: CreateGreenlandMerchantPage,
    pageId: 'create-greenland-merchant',
    path: '/create-greenland-merchant',
    route: '/create-greenland-merchant',
    isPrivate: true,
  },
};

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  return (
    <BrowserRouter>
      <Root>
        <Switch>
          {Object.values(ROUTES).map((route) => {
            const RouteContainer = route.isPrivate ? PrivateRoute : PublicRoute;

            return (
              <RouteContainer
                exact
                key={route.pageId}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </Root>
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
