import { Button, Empty, Modal, PaginationProps, Spin } from 'antd';
import { getSettlementsMerchantApi } from 'apis/settlementAPI';
import { getTransactions } from 'apis/transactionAPI';
import { Box, Space } from 'components';
import {
  AddOwnerModal,
  MerchantAcquirerForm,
  MerchantBankForm,
  MerchantDetailsForm,
  MerchantDocuments,
  MerchantEcomForm,
  MerchantNoteForm,
  MerchantOwnersDetails,
  MerchantRevenueForm,
  MerchantStatusDetails,
  MerchantTerminalDetails,
  MerchantType,
  SandboxModal,
} from 'features/merchants/components';
import { MerchantContactForm } from 'features/merchants/components/MerchantContactForm';
import { MerchantUserDetails } from 'features/merchants/components/MerchantUserDetails';
import { useMerchant } from 'features/merchants/hooks';
import { merchantSelector } from 'features/merchants/redux';
import { BaseLayout } from 'features/navigation/components';
import { SettlementsTable } from 'features/settlements/SettlementsTable/SettlementsTable';
import { TransactionsTable } from 'features/transactions';
import { userSelector } from 'features/user/redux/selectors';
import { FirebaseCollectionsType, getUserDatas } from 'firebaseAPI';
import { orderBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router';
import { styled } from 'theme';
import type { Firebase } from 'types/firebase';
import { SoftPos } from 'types/softpos';
import * as Styles from './MerchantPage.styles';
import { ProxyUserSettings } from 'features/settings';

const StyledEmpty = styled(Empty)`
  && {
    margin: 50;
  }
`;

const MerchantPage: React.FC = () => {
  const [sandboxModal, setSandboxModal] = useState<boolean>(false);
  const [trnxItems, setTrnxItems] = useState<SoftPos.TrxnRaw[]>([]);
  const [stlmItems, setStlmItems] = useState<SoftPos.Settlement[]>([]);
  const [users, setUsers] = useState<Firebase.UserData[]>([]);
  const [lastTransactionDate] = useState<string | null>();
  const [transactionsError, setTransactionError] = useState<string | null>();
  const [settlementError, setSettlementError] = useState<string | null>();
  const { merchantId } = useParams<{ merchantId: string }>();
  const { updateMerchant, updateTerminalIMEI } = useMerchant(merchantId);
  const SOFTPOS_START_DATE = '2021-06-01T00:00:00.000Z';
  const user = useSelector(userSelector);

  const fetchUsers = React.useCallback(async () => {
    setUsers(await getUserDatas(merchantId));
  }, [merchantId]);

  const fetchTransactions = React.useCallback(async () => {
    try {
      const resp = await getTransactions(
        moment().subtract(2, 'weeks'),
        moment(),
        merchantId,
      );
      const trxnItems = orderBy(resp, ['utcTime'], ['desc']);
      setTrnxItems(trxnItems);
    } catch (exception) {
      setTransactionError(exception?.message);
    }
  }, [merchantId]);

  const fetchSettlements = React.useCallback(async () => {
    try {
      const settlementResults = await getSettlementsMerchantApi(
        merchantId,
        0,
        10,
      );
      const stlmItems = orderBy(settlementResults, ['startedAt'], ['desc']);
      setStlmItems(stlmItems);
    } catch (exception) {
      setSettlementError(exception?.message);
    }
  }, [merchantId]);

  React.useEffect(() => {
    fetchTransactions();
    fetchSettlements();
    fetchUsers();
  }, [merchantId]);

  const merchant: Firebase.Merchant = useSelector(merchantSelector(merchantId));

  useFirestoreConnect({
    collection: FirebaseCollectionsType.MERCHANTS,
    storeAs: `merchant_${merchantId}`,
    doc: merchantId,
  });

  const handleUpdatingTerminalIMEI = useCallback(
    (terminalId: string, value: string) => {
      return updateTerminalIMEI(merchantId, terminalId, { imei: value });
    },
    [merchantId, updateTerminalIMEI],
  );

  const handleNoTransactions = useCallback(() => {
    if (!lastTransactionDate) {
      return (
        <StyledEmpty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No transactions"
        />
      );
    }
    return (
      <div>
        <Space size="xl" />
        Last transaction happened at: {lastTransactionDate}
      </div>
    );
  }, [lastTransactionDate]);

  const handleUpdateProperty = useCallback(
    (name: string, value: any) => {
      return updateMerchant({ [name]: value });
    },
    [updateMerchant],
  );

  const handleChangeReadyForApplication = useCallback(
    (readyForApplication: number | null) => {
      return updateMerchant({ readyForApplication });
    },
    [updateMerchant],
  );

  const handleChangeOwnerData = useCallback(
    (ownerIndex: number, property: string, value: string | number) => {
      const ownerData = merchant?.ownerData?.map((owner, index) => {
        if (index === ownerIndex) {
          return {
            ...owner,
            [property]: value,
          };
        }

        return owner;
      });

      return handleUpdateProperty('ownerData', ownerData);
    },
    [handleUpdateProperty, merchant?.ownerData],
  );

  const handleChangeAcquirerFields = useCallback(
    (inputName: string, value: string | number, forced = false) => {
      if (inputName === 'mcc') {
        handleUpdateProperty(inputName, value);
      } else if (inputName === 'merchantContract') {
        handleUpdateProperty(inputName, value);
      } else {
        Modal.confirm({
          title: 'Confirm',
          content: 'Are you sure the merchant contract is correct?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: () => {
            const newClearhausMerchantId = value.toString().trim();
            handleUpdateProperty(inputName, newClearhausMerchantId);
            handleUpdateProperty('clearhausKYCdone', Date.now());
          },
        });
      }
    },
    [handleUpdateProperty],
  );

  if (!merchant) {
    return (
      <BaseLayout fullHeight>
        <Spin />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <SandboxModal
        merchant={merchant}
        showSandboxModal={sandboxModal}
        hideSandboxModal={() => setSandboxModal(false)}
      />
      <AddOwnerModal merchant={merchant} />
      <Styles.Container>
        <MerchantStatusDetails
          merchant={merchant}
          onChangeReadyForApplication={handleChangeReadyForApplication}
        />
        <Styles.GridContainer>
          <div>
            <Box display="flex" flexDirection="column">
              {user.userRoles.includes('push-to-pay') &&
                !merchant.enabledFeatures?.includes('push-to-pay') && (
                  <Button onClick={() => setSandboxModal(true)}>
                    Enable and add sandbox user
                  </Button>
                )}
              <ProxyUserSettings merchant={merchant} />
            </Box>
            <MerchantOwnersDetails
              merchantId={merchant.merchantId}
              onboardingId={merchant.onboardingId}
              owners={merchant.ownerData}
              onSave={handleChangeOwnerData}
            />
            <Space size="xl" />
            <MerchantDocuments merchant={merchant} />
            <Space size="xl" />
            <MerchantTerminalDetails
              merchantId={merchantId}
              accountId={merchant.accountId}
              terminals={merchant.terminalCollection}
              onSave={handleUpdatingTerminalIMEI}
            />
            <MerchantUserDetails merchantId={merchantId} users={users} />
            <Space size="xl" />
            {merchant.contact && (
              <MerchantContactForm
                contact={merchant.contact}
                onSave={handleUpdateProperty}
              />
            )}
          </div>
          <div>
            <MerchantType merchant={merchant} onSave={handleUpdateProperty} />
            <Space size="xl" />
            <MerchantNoteForm
              merchant={merchant}
              onSave={handleUpdateProperty}
            />
            <MerchantEcomForm
              merchant={merchant}
              onSave={handleUpdateProperty}
            />
            <Space size="xl" />
            <MerchantDetailsForm
              merchant={merchant}
              onSave={handleUpdateProperty}
            />

            <MerchantBankForm
              merchant={merchant}
              onSave={handleUpdateProperty}
            />

            <MerchantAcquirerForm
              merchant={merchant}
              onSave={handleChangeAcquirerFields}
            />

            <MerchantRevenueForm
              merchant={merchant}
              onSave={handleUpdateProperty}
            />
          </div>
        </Styles.GridContainer>
      </Styles.Container>
      {transactionsError ? (
        <div>{transactionsError}</div>
      ) : trnxItems.length > 0 ? (
        <TransactionsTable
          trxnItems={trnxItems}
          paginate={undefined}
          merchantsFilterOpt={[]}
          isFetching={false}
          tableChangeHandler={(pagination: PaginationProps) => {}}
        />
      ) : (
        handleNoTransactions()
      )}
      {settlementError ? (
        <div>{settlementError}</div>
      ) : stlmItems.length > 0 ? (
        <SettlementsTable stlmItems={stlmItems} />
      ) : (
        <StyledEmpty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No settlements"
        />
      )}
    </BaseLayout>
  );
};

export { MerchantPage };
