import { Tag } from 'antd';
import { Box, CopyButton } from 'components';
import { BaseLayout } from 'features/navigation/components';
import { userSelector } from 'features/user/redux/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

const SettingsPage: React.FC = () => {
  const authenticatedUser = useSelector(userSelector);
  return (
    <BaseLayout>
      <h1>Settings</h1>
      <Box mt="32px" />
      <h5>Current user</h5>
      <Box
        width="auto"
        mt="8px"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Tag style={{ color: 'green' }}>
          <Box>E-mail: {authenticatedUser?.email}</Box>
        </Tag>
        <Box>
          <CopyButton text={authenticatedUser?.email?.toString() ?? ''} />
        </Box>
      </Box>
      <Box
        mt="8px"
        width="auto"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Tag style={{ color: 'green' }}>
          <Box>UserID: {authenticatedUser?.id}</Box>
        </Tag>
        <Box>
          <CopyButton text={authenticatedUser?.id?.toString() ?? ''} />
        </Box>
      </Box>
      <Box
        mt="8px"
        width="auto"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Tag style={{ color: 'green' }}>
          <Box>AccountID: {authenticatedUser?.accountId}</Box>
        </Tag>
        <Box>
          <CopyButton text={authenticatedUser?.accountId?.toString() ?? ''} />
        </Box>
      </Box>
      <Box
        mt="8px"
        width="auto"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Tag style={{ color: 'green' }}>
          <Box>MerchantID: {authenticatedUser?.meta?.merchantId}</Box>
        </Tag>
        <Box>
          <CopyButton
            text={authenticatedUser?.meta?.merchantId?.toString() ?? ''}
          />
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { SettingsPage };
